<script setup>
import { computed, markRaw, ref } from "vue";
import { usePage } from "@inertiajs/vue3";
import { useAppStore } from "@/stores/AppStore";
import SideNav from "./SideNav.vue";
import MobileNavBar from "./MobileNavBar.vue";
import _ from "lodash";

// ICONS
import { ChartBarIcon } from "@heroicons/vue/20/solid";
import ManpowerIcon from "@/Components/Icons/ManpowerIcon.vue";
import ProjectsIcon from "@/Components/Icons/ProjectsIcon.vue";
import CalenderIcon from "@/Components/Icons/CalenderIcon.vue";
import SettingsIcon from "@/Components/Icons/SettingsIcon.vue";
import WorkOrderIcon from "@/Components/Icons/WorkOrderIcon.vue";
import ChartLine from "@/Components/Icons/ChartLine.vue";
import ContactsIcon from "@/Components/Icons/ContactsIcon.vue";
import CustomerIcon from "@/Components/Icons/CustomerIcon.vue";
import FileContract from "@/Components/Icons/FileContract.vue";
import InventoryIcon from "@/Components/Icons/InventoryIcon.vue";
import SubcontractorIcon from "@/Components/Icons/SubcontractorIcon.vue";
import AnnouncementIcon from "@/Components/Icons/AnnouncementIcon.vue";
import TravelIcon from "@/Components/Icons/TravelIcon.vue";
import FleetIcon from "@/Components/Icons/FleetIcon.vue";
import PmCheckoutIcon from "@/Components/Icons/PmCheckoutIcon.vue";
import TimeIcon from "@/Components/Icons/TimeIcon.vue";
import EmployeeIcon from "@/Components/Icons/EmployeeIcon.vue";
import TCAIcon from "@/Components/Icons/TCAIcon.vue";
import ConesIcon from "@/Components/Icons/ConesIcon.vue";

const app = useAppStore();
const page = usePage();

const canUserAccess = ref(page.props.can);
const isRoleFullAccess = computed(() =>
    _.sumBy(
        page.props.auth.user.roles,
        (role) => role.name === "full_access" || role.name === "development",
    ),
);

const navItems = markRaw([
    {
        icon: WorkOrderIcon,
        title: "Schedule",
        link: "/schedule",
        children: [],
        display: true,
    },
    {
        icon: CalenderIcon,
        title: "Calendar",
        urlSlug: "calendar",
        link: "/calendar",
        children: [],
        display: isRoleFullAccess.value,
    },
    {
        icon: ProjectsIcon,
        title: "Projects",
        urlSlug: "projects",
        link: "/projects",
        children: [],
        display: canUserAccess.value.projects_view,
    },
    {
        icon: ManpowerIcon,
        title: "Manpower",
        urlSlug: "manpower",
        link: undefined,
        display: canUserAccess.value.view_manpower_schedule,
        children: [
            { label: "Schedule", link: "/manpower/schedule", display: true },
            {
                label: "Crew",
                link: "/manpower/crew",
                display: canUserAccess.value.view_manpower_crew,
            },
            {
                label: "Assign",
                link: "/manpower/assign",
                display: canUserAccess.value.view_manpower_assign,
            },
        ],
    },
    {
        icon: PmCheckoutIcon,
        title: "PM Check Out",
        link: "/checkout",
        urlSlug: "checkout",
        display: canUserAccess.value.checkout_view,
        children: [],
    },
    {
        icon: CustomerIcon,
        title: "Customers",
        urlSlug: "customers",
        link: "/customers",
        children: [],
        display: canUserAccess.value.customers_view,
    },
    {
        icon: ContactsIcon,
        title: "Contacts",
        urlSlug: "contacts",
        link: "/contacts",
        children: [],
        display: canUserAccess.value.contacts_view,
    },
    {
        icon: SubcontractorIcon,
        title: "SubContractors",
        urlSlug: "subcontractors",
        link: "/subcontractors",
        children: [],
        display: canUserAccess.value.subcontactors_view,
    },
    {
        icon: FleetIcon,
        title: "Fleet/Equipments",
        urlSlug: "equipment",
        link: "/equipment",
        children: [],
        display: canUserAccess.value.fleet_view,
    },
    {
        icon: ConesIcon,
        title: "HR Links",
        urlSlug: "hr",
        link: "/hr-self-service",
        children: [
            {
                label: "Employee Handbook",
                link: "https://s3-us-west-1.amazonaws.com/holbrook-asphalt/HR/200921_EmployeePolicyManualV13.pdf",
                external: true,
            },
            {
                label: "Safety Manual",
                link: "https://s3-us-west-1.amazonaws.com/holbrook-asphalt/HR/Holbrook_Safety_Manual.pdf",
                external: true,
            },
            {
                label: "Employment Opportunities",
                link: "https://recruiting.paylocity.com/Recruiting/Jobs/All/68bf8f66-c07a-4b0e-947b-ee3d72e0ab05/Holbrook-Asphalt-LLC",
                external: true,
            },
            {
                label: "Refer an Employee",
                link: "https://holbrookasphalt.com/employee-referral-form/",
                external: true,
            },
            {
                label: "Paylocity",
                link: "https://access.paylocity.com/",
                external: true,
            },
            {
                label: "Training Videos",
                link: "/training/videos",
                external: true,
            },
        ],
        display: true,
    },
    {
        icon: TCAIcon,
        title: "TCA",
        urlSlug: "time-card-approval",
        link: "",
        children: [
            { label: "TCA", link: "/time-card-approval" },
            { label: "Supervisor", link: "/time-card-approval/supervisor" },
        ],
        display: canUserAccess.value.timecard_approval_view,
    },
    {
        icon: EmployeeIcon,
        title: "HR",
        urlSlug: "hr",
        link: "/hr",
        children: [
            { label: "Employees", link: "/employees" },
            {
                label: "Paylocity Report",
                link: "/reports/paylocity",
            },
        ],
        display: canUserAccess.value.users_view,
    },
    {
        icon: TimeIcon,
        title: "TimeCard",
        urlSlug: "time",
        link: "/time/card",
        children: [],
        display: canUserAccess.value.timecard_view,
    },
    {
        icon: AnnouncementIcon,
        title: "Announcement",
        urlSlug: "announcements",
        link: "/announcements",
        children: [],
        display: canUserAccess.value.announcements_view,
    },
    {
        icon: FileContract,
        title: "Finance",
        urlSlug: "billing",
        link: "/billing",
        children: [
            { label: "Billing", link: "/billing" },
            { label: "By Project", link: "/billing/by-project" },
        ],
        display: canUserAccess.value.billing_view,
    },
    {
        icon: ChartBarIcon,
        title: "Reports",
        urlSlug: "reports",
        link: "#",
        children: [
            {
                label: "Payroll Report",
                link: "/reports/payroll",
            },
        ],
        display: canUserAccess.value.reports_view,
    },
    {
        icon: SettingsIcon,
        title: "Settings",
        urlSlug: false,
        display: isRoleFullAccess.value,
        link: "",
        children: [
            { label: "Services", link: "/services" },
            { label: "Locations", link: "/locations" },
            { label: "Groups", link: "/groups" },
            { label: "Regions", link: "/regions" },
            { label: `Project Status's`, link: "/management/project-statuses" },
            { label: "Roles/Permission", link: "/guard" },
            { label: "Project Categories", link: "/project-categories" },
            { label: "TimeCard Types ", link: "/timecard-types" },
            { label: "Location Types ", link: "/location-types" },
            { label: "Companies", link: "/companies" },
        ],
    },
    {
        icon: TravelIcon,
        title: "Travel/MOB",
        urlSlug: "travel",
        link: "/travel",
        urlTitle: "Travel",
        children: [],
        display: isRoleFullAccess.value,
    },
    {
        icon: ChartLine,
        title: "Performance",
        urlSlug: "performance",
        link: "/performance",
        children: [],
        display: isRoleFullAccess.value,
    },

    {
        icon: InventoryIcon,
        title: "Inventory",
        urlSlug: "inventory",
        link: "/inventory",
        children: [],
        display: isRoleFullAccess.value,
    },
]);
</script>

<template>
    <div>
        <SideNav :nav-items="navItems" />

        <transition
            enter-active-class="animate__animated animate__faster animate__slideInLeft"
            leave-active-class="animate__animated animate__faster animate__slideOutLeft"
        >
            <MobileNavBar v-if="app.getMobileNavState" :nav-items="navItems" />
        </transition>
    </div>
</template>

<style lang="scss" scoped></style>
